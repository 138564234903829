<template lang="pug">
b-card.adminCard
  b-form
    b-form-group(label='Month')
      b-form-select(v-model='form.month' :state="validateState('month')" :options='monthOptions')
      b-form-invalid-feedback This field is required
    b-form-group(label='Day')
      b-form-input(v-model='form.day' :state="validateState('day')")
      b-form-invalid-feedback This field is required
    b-form-group(label='Upcharge')
      b-input-group(size="lg" append="%")
        b-form-input(v-model='form.upcharge' :state="validateState('upcharge')")
      b-form-invalid-feedback This field is required
    b-form-group
      b-button(@click='submit' :disabled='processing')
        b-spinner(small v-if='processing').mr-2
        span Save
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { maxValue, minValue, required } from 'vuelidate/lib/validators'
import { Maybe, UpchargeQuery } from '@/generated/graphql'

@Component({ components: {} })
export default class Form extends Vue {
  process = false

  @Prop() upcharge: Maybe<UpchargeQuery['upcharge']>
  @Prop() saving

  get processing () {
    return this.saving
  }

  get monthOptions () {
    return [
      { value: 1, text: 'January' },
      { value: 2, text: 'February' },
      { value: 3, text: 'March' },
      { value: 4, text: 'April' },
      { value: 5, text: 'May' },
      { value: 6, text: 'June' },
      { value: 7, text: 'July' },
      { value: 8, text: 'August' },
      { value: 9, text: 'September' },
      { value: 10, text: 'October' },
      { value: 11, text: 'November' },
      { value: 12, text: 'December' }
    ]
  }

  form = {
    month: 1,
    day: 1,
    upcharge: 10
  } as { month: number, day: number, upcharge: number}

  validations () {
    return {
      form: {
        day: { required, minValue: minValue(1), maxValue: maxValue(31) },
        month: { required },
        upcharge: { required }
      }
    }
  }

  validateState (name) {
    const $dirty = this.$v.form[name]?.$dirty
    const $error = this.$v.form[name]?.$error
    return $dirty ? !$error : null
  }

  created () {
    if (this.upcharge) {
      this.form.day = this.upcharge.day
      this.form.month = this.upcharge.month
      this.form.upcharge = this.upcharge.upcharge
    }
  }

  async submit () {
    this.process = true
    this.$v.form.$touch()
    if (this.$v.form.$anyError) return
    this.form.day = Number(this.form.day)
    this.form.month = Number(this.form.month)
    this.form.upcharge = Number(this.form.upcharge)
    this.$emit('save', this.form)
    this.process = false
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
</style>
