<template lang="pug">
#app
  router-view
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import CartStore from '@/store/modules/cart'
import 'vue-select/dist/vue-select.css'
@Component({ components: { } })
export default class App extends Vue {
  mounted () {
    CartStore.load()
  }
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Lexend+Peta&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  .bg-dark {
    background-color: #1d1d1d!important;
  }
}
#admin {
  font-family: "Nunito", sans-serif;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.outlined {
  outline: solid 1px #d0a14f;
  outline-offset: -15px;
}
.btn {
  border-radius: 0;
  font-family: 'Oswald';
  font-weight: 500;
  font-size: 15px;
  text-transform: uppercase;
  line-height: 1;
  padding: 15px 50px;
  &.green {
    background-color: #D4AF37;
  }
  &.btn-sm {
    padding: 10px 20px;
    font-size: 12px;
  }
}
.fancy {
  font-family: "Oswald", sans-serif;
}
.price {
  font-family: "Lato", sans-serif;
}
.theme-button {
  min-height: 4rem;
  cursor: pointer;
  padding: 0 2em;
  align-self: stretch;
  background-color: #d3af36;
  font-family: "Lexend Peta", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: transparent;
  border-radius: 0;
  h1 {
    display: flex;
    font-size: 1.2em;
  }
  &:hover {
    background-color: #d3a510;
  }
}
</style>
