<template lang="pug">
  .dashboard
    b-container(fluid)
      b-row
        b-col
          h2.fancy Dashboard
      b-row
        b-col
          b-card.h-100(title='Weekly Sales By Room')
            .chart(ref='rooms')
        b-col
          b-card.h-100(title='Weekly Sales by Extra')
            .chart(ref='extras')
        b-col
          b-card.h-100(title='Reservations Today')
            .text-center
              h2 {{ countReservation }}
      b-row.mt-2
        b-col
          b-card(title='Reservation Booking Sales')
            .chart(ref='sales')
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import ApexCharts from 'apexcharts'
import Client from '@/graphql/client'
import { DashboardQuery, DashboardQueryVariables, Maybe } from '@/generated/graphql'
import DashboardGQL from '@/graphql/queries/dashboard.graphql'

const formatCurrency = (amount: number) => {
  const suffixes = [
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'B' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' }
  ]

  const suffix = suffixes.find(({ value }) => Math.abs(amount) >= value)

  if (suffix) {
    const formatted = (amount / suffix.value).toFixed(1)
    return '$' + formatted.replace(/\.0$/, '') + suffix.symbol
  }

  return '$' + amount.toString()
}

@Component({ components: {} })
export default class Dashboard extends Vue {
  data: Maybe<DashboardQuery['dashboard']> = null

  get countReservation () {
    return this.data?.reservationsToday
  }

  get rooms () {
    if (!this.data) return {}
    const sorted = this.data.rooms!.sort((a, b) => a!.value - b!.value)
    return {
      chart: {
        type: 'bar'
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return formatCurrency(val)
        },
        style: {
          colors: ['#fff']
        },
        offsetY: 0
      },
      series: [
        {
          name: 'sales',
          data: sorted.map((room) => room?.value)
        }
      ],
      yaxis: {
        labels: {
          formatter: function (value) {
            return formatCurrency(value)
          }
        }
      },
      xaxis: {
        categories: sorted.map((room) => room?.name.split('(')[0])
      }
    }
  }

  get extras () {
    if (!this.data) return {}
    const sorted = this.data.extras!.sort((a, b) => a!.value - b!.value)
    return {
      chart: {
        type: 'bar'
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return formatCurrency(val)
        },
        style: {
          colors: ['#fff']
        },
        offsetY: 0
      },
      series: [
        {
          name: 'sales',
          data: sorted.map((room) => room?.value)
        }
      ],
      yaxis: {
        labels: {
          formatter: function (value) {
            return formatCurrency(value)
          }
        }
      },
      xaxis: {
        categories: sorted.map((room) => room?.name)
      }
    }
  }

  get sales () {
    const dataset = this.data!.reservations!
      .map(i => ({ name: new Date(i!.name).getTime(), value: i!.value }))
      .sort((a, b) => b.name - a.name)
    const data = dataset.map(i => i!.value)
    const labels = dataset.map(i => i!.name)
    return {
      labels,
      series: [{
        name: 'Sales',
        data
      }],
      chart: {
        height: 350,
        type: 'area'
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime'
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return formatCurrency(val)
        }
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return formatCurrency(value)
          }
        }
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy'
        }
      }
    }
  }

  async mounted () {
    this.data = (await Client.query<DashboardQuery, DashboardQueryVariables>(
      DashboardGQL
    )).dashboard
    console.log(this.data)
    this.$nextTick(() => {
      new ApexCharts(this.$refs.rooms, this.rooms).render()
      new ApexCharts(this.$refs.extras, this.extras).render()
      new ApexCharts(this.$refs.sales, this.sales).render()
    })
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  .nav {
    padding: 10px;
    text-align: left;
    font-size: 0.8em;
  }
}
</style>
