<template lang="pug">
  .rooms
    .breadcrumbs
      h2 Manage Upcharges
    .table
      b-card.adminCard
        .actions
          b-button(size='sm' variant='primary' :to="{name: 'createUpcharge'}")
            b-icon-plus-square-fill.mr-2
            span Create
        b-table(striped hover fixed :fields='fields' :items="upcharges" :busy="loading" show-empty)
          template(#empty="scope")
            h5.text-center No upcharges yet
          template(v-slot:table-busy)
            .text-center.text-danger.my-2
              b-spinner.align-middle
                strong Loading...
          template(v-slot:cell(day)="data")
            span {{ data.item.day }}
          template(v-slot:cell(month)="data")
            span {{ monthToString(data.item.month) }}
          template(v-slot:cell(upcharge)="data")
            span {{ data.item.upcharge }}%
          template(v-slot:cell(actions)="data")
            router-link(:to="{name: 'editUpcharge', params: {id: data.item.id}}")
              b-icon-pencil.mr-2
            b-icon-trash.link(@click='deleteUpcharge(data.item.id)')
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { sdk } from '@/graphql/client'
import swal from 'sweetalert2'
import {
  UpchargesQuery
} from '@/generated/graphql'
@Component({ components: {} })
export default class Upcharges extends Vue {
  upcharges: UpchargesQuery['upcharges'] = []
  fields = ['day', 'month', 'upcharge', 'actions']
  loading = false
  mounted () {
    this.loadUpcharges()
  }

  async loadUpcharges () {
    this.loading = true
    const response = await sdk.Upcharges()
    this.upcharges = response.upcharges
    this.loading = false
  }

  monthToString (month: number): string {
    switch (month) {
      case 1:
        return 'January'
      case 2:
        return 'February'
      case 3:
        return 'March'
      case 4:
        return 'April'
      case 5:
        return 'May'
      case 6:
        return 'June'
      case 7:
        return 'July'
      case 8:
        return 'August'
      case 9:
        return 'September'
      case 10:
        return 'October'
      case 11:
        return 'November'
      case 12:
        return 'December'
    }
    return 'Unknown'
  }

  async deleteUpcharge (id: number) {
    const confirm = await swal.fire({
      title: 'Are you sure?',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text:
          'Once deleted, you will not be able to recover this upcharge',
      icon: 'warning'
    })
    if (confirm.isDismissed) { return }
    this.loading = true
    await sdk.DeleteUpcharge({ upcharge: id })
    await this.loadUpcharges()
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
.link {
  cursor: pointer;
  color: #007bff;
  &:hover {
    color: #0056b3;
  }
}

.avatar {
  max-width: 100px;
}

.position {
  width: 60px;
}
</style>
