<template lang="pug">
#admin
  b-navbar(type="dark" fixed='top').bg-dark
    b-navbar-brand LALA
    b-navbar-nav.ml-auto
      b-nav-item(@click='signout') Sign Out
  b-container(fluid)
    b-row
      b-col(md='2').bg-light.sidebar
        .sidebar-sticky
          b-nav(vertical pills)
            b-nav-item(:to="{name: 'dashboard'}" active-class="active") Dashboard
            b-nav-item(:to="{name: 'rooms'}" active-class="active" v-if='isAdmin') Rooms
            b-nav-item(:to="{name: 'extras'}" active-class="active" v-if='isAdmin') Extras
            b-nav-item(:to="{name: 'reservations'}" active-class="active") Reservations
            b-nav-item(:to="{name: 'giftcards'}" active-class="active") Giftcards
            b-nav-item(:to="{name: 'waiters'}" active-class="active" v-if='isAdmin') Waiters
            b-nav-item(:to="{name: 'admins'}" active-class="active" v-if='isAdmin') Admins
            b-nav-item(:to="{name: 'taxReporting'}" active-class="active" v-if='isAdmin') Tax Reporting
            b-nav-item(:to="{name: 'taxReportingNew'}" active-class="active" v-if='isAdmin') Tax Reporting New
            b-nav-item(:to="{name: 'upcharges'}" active-class="active" v-if='isAdmin') Upcharges
            b-nav-item(:to="{name: 'promotions'}" active-class="active" v-if='isAdmin') Promotions
            b-nav-item(:to="{name: 'settings'}" active-class="active" v-if='isAdmin') Settings
      b-col(md='10').main.px-4.ml-sm-auto
        router-view
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import 'animate.css'
import { Role } from './generated/graphql'
import { sdk } from './graphql/client'
@Component({ components: {} })
export default class AdminLayout extends Vue {
  async signout () {
    await sdk.Logout()
    this.$currentUser.clear()
    this.$router.push({ name: 'login' })
  }

  get isAdmin () {
    return this.$currentUser.role === Role.Admin
  }
}
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  .nav {
    padding: 10px;
    text-align: left;
    font-size: 0.8em;
  }
}

.main {
  padding-top: 65px;
  text-align: left;
}

body {
  font-family: "Nunito", sans-serif;
}
</style>

<style lang="scss">
.adminCard {
  border-radius: 10px;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
  border: 0;
}
</style>
