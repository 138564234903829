<template lang="pug">
  .rooms
    .breadcrumbs
      h2 Create an Upcharge
      UpchargeForm(:saving='saving' @save='save')
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { sdk } from '@/graphql/client'
import UpchargeForm from './Form.vue'

@Component({ components: { UpchargeForm } })
export default class createUpcharge extends Vue {
  saving = false
  async save (payload) {
    this.saving = true
    await sdk.CreateUpcharge({ upcharge: payload })
    console.log(payload)
    this.saving = false
    this.$router.push({
      name: 'upcharges'
    })
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
</style>

<style lang="scss">
.filepond--item {
  width: 50%;
}
</style>
