<template lang="pug">
  .rooms(v-if='upcharge')
    .breadcrumbs
      h2 Edit
    UpchargeForm(:saving='saving' :upcharge='upcharge' @save='save')
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { sdk } from '@/graphql/client'
import swal from 'sweetalert2'
import {
  Maybe,
  UpchargeQuery
} from '@/generated/graphql'
import UpchargeForm from './Form.vue'
@Component({ components: { UpchargeForm } })
export default class EditUpcharge extends Vue {
  saving = false
  upcharge: Maybe<UpchargeQuery['upcharge']> = null

  @Prop({ required: true }) id!: string

  async mounted () {
    this.upcharge = (await sdk.Upcharge({ id: parseInt(this.id) })).upcharge
  }

  async save (payload) {
    this.saving = true
    await sdk.UpdateUpcharge(
      { id: parseInt(this.id), upcharge: payload }
    )
    this.saving = false
    swal.fire('Awesome!', 'upcharge has been updated!', 'success')
    this.$router.push({
      name: 'upcharges'
    })
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
</style>
