<template lang="pug">
div
  b-modal(id='viewReservation' title='Reservation Details' ref='modal' :no-enforce-focus='true' size="xl")
    .content(v-if='reservation')
      b-tabs(content-class="mt-3" justified)
        b-tab(title="Client Details")
          .paragraph
            h5 Name
            span {{ reservation.client.name }}
          .paragraph
            h5 Party
            span {{ reservation.partyName }}
          .paragraph
            h5 Email
            span {{ reservation.client.email }}
          .paragraph
            h5 Phone
            span {{ reservation.phoneNumber }}
          .paragraph(v-if='hasNotes')
            h5 Notes
            span {{ reservation.notes }}
          .paragraph
            h5 Private Notes
            b-form-row
              b-form-textarea(v-model='reservation.adminNotes')
          .paragraph
            h5 Assigned Waiter
            v-select(:options='waiterOptions' :value='waiter' @input='setWaiter')
        b-tab(title='Order Details')
          div(v-for='(slots, room) in rooms').mb-3
            h5 {{ room }}
            ul
              li(v-for='slot in slots')
                span {{ slot.startTime | timeFormat }} - {{ slot.endTime | timeFormat }}
          div(v-if='extras && extras.length')
            h5 Extras
            ul
              li(v-for='extra in extras')
                span {{ extra.title }} x {{ extra.quantity }}
        b-tab(title='Receipt Details')
          div.mb-3
            b-table(:items='lineItems' :fields='fields' striped hover small)
              template(#cell(baseAmount)="data")
                span {{ data.item.baseAmount / 100 | toCurrency }}
              template(#cell(taxAmount)="data")
                span {{ data.item.taxAmount / 100 | toCurrency }}
              template(#cell(discountAmount)="data")
                span {{ data.item.discountAmount / 100 | toCurrency }}
              template(#cell(total)="data")
                span {{ data.item.total / 100 | toCurrency }}
              template(#cell(date)="data")
                span {{ data.item.createdAt | dateFormat }}
              template(#cell(refundedDate)="data")
                span {{ data.item.refundedDate | dateFormat }}
              template(#cell(actions)="data")
                div(v-if='!data.item.refunded && data.item.category == "ROOM" && data.item.baseAmount > 0')
                  b-button(size='sm' @click="setupPartialRefund(data)") Adjust Room Cost
              template(#row-details="data")
                b-card
                  b-form
                    b-form-group(label="New Room Cost" description='Enter the new cost of the room')
                      b-input(min="1" :max='refundState.initialCost' v-model="refundState.amount" size="sm" type='number' :state="validateState('amount')")
                      b-form-invalid-feedback(v-if="!$v.refundState['amount'].minValue") New price cannot be less than 1
                      b-form-invalid-feedback(v-if="!$v.refundState['amount'].maxValue") Max price cannot be more than {{ refundState.initialCost - 1 }}
                      b-form-invalid-feedback(v-if="!$v.refundState['amount'].required") Amount is required
                    b-form-group(label="Details")
                      div
                        span Room Refund : {{ refundAmount | toCurrency }}
                      div
                        span Tax Refund: {{ taxRefundAmount | toCurrency }}
                      div
                        span Total Refund Amount: {{ totalRefundAmount | toCurrency }}
                    b-button(size='sm' :diabled='refunding' @click='adjustCost')
                      b-spinner(v-if='refunding').mr-2
                      span Refund
          hr
    template(v-slot:modal-footer)
      b-button(variant='info' @click='rebook').mr-auto Rebook
      b-button(v-if='!isManual' variant='danger' :to="{name: 'manage', params: { id: reservation.stripeReference }, query: {lastMinute: true}}"  target="_blank") Cancel Reservation
      b-button(v-else @click='cancelManual') Cancel Manual Reservation
      b-button(variant='primary' @click='close') Close
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { BookedReservationsQuery, ReservationTimeSlot, WaitersQuery, Role, ReservationStatus } from '@/generated/graphql'
import groupBy from 'lodash/groupBy'
import { EventClickArg } from '@fullcalendar/core'
import { sdk } from '@/graphql/client'
import { format } from 'date-fns'
import { maxValue, minValue, required } from 'vuelidate/lib/validators'

type ReservationEvent = EventClickArg['event'] & { extendedProps: { reservation: BookedReservationsQuery['reservations'][0] } }

@Component({ components: {} })
export default class ReservationModal extends Vue {
  @Prop() event!: ReservationEvent
  @Prop() waiters!: WaitersQuery['waiters']

  refundState = {
    lineItemId: 0,
    initialCost: 0,
    amount: 0,
    cancel: true,
    pin: null
  }

  refunding = false

  setupPartialRefund (data: {toggleDetails: () => void, item: BookedReservationsQuery['reservations'][0]['reservationLineItems'][0]}) {
    this.refundState.lineItemId = data.item.id
    this.refundState.amount = this.maxRefund(data.item)
    this.refundState.initialCost = this.refundState.amount
    data.toggleDetails()
  }

  maxRefund (lineItem: BookedReservationsQuery['reservations'][0]['reservationLineItems'][0]) {
    return ((lineItem.baseAmount - lineItem.discountAmount) - lineItem.partialRefundAmount) / 100
  }

  validations () {
    return {
      refundState: {
        amount: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(this.refundState.initialCost - 1)
        }
      }
    }
  }

  validateState (name) {
    const $dirty = this.$v.refundState[name]?.$dirty
    const $error = this.$v.refundState[name]?.$error
    return $dirty ? !$error : null
  }

  get refundAmount () {
    return this.refundState.initialCost - this.refundState.amount
  }

  get taxRefundAmount () {
    return this.refundAmount * 0.07
  }

  get totalRefundAmount () {
    return this.refundAmount + this.taxRefundAmount
  }

  get waiterOptions () {
    return this.waiters.map((waiter) => {
      return {
        value: waiter.id,
        label: `${waiter.firstname} ${waiter.lastname}`
      }
    })
  }

  get fields () {
    return [
      { key: 'description', label: 'description' },
      { key: 'baseAmount', label: 'Base' },
      { key: 'taxAmount', label: 'Tax' },
      { key: 'discountAmount', label: 'Discount' },
      { key: 'total', label: 'Total' },
      { key: 'date', label: 'Date' },
      { key: 'refundedDate', label: 'Refunded' },
      { key: 'actions', label: 'Action' }
    ]
  }

  get lineItems () {
    if (!this.reservation) return []
    return this.reservation.reservationLineItems.sort((a, b) => { return a.createdAt === b.createdAt ? 0 : a.createdAt ? 1 : -1 })
  }

  get reservation () {
    if (!this.event) return
    return this.event.extendedProps.reservation
  }

  get isManual () {
    return this.reservation?.status === ReservationStatus.Manual
  }

  get extras () {
    if (!this.reservation) return []
    return this.reservation.reservationExtras
  }

  get rooms () {
    if (!this.reservation) return []
    return groupBy(this.reservation.reservationTimeSlots, (slot: ReservationTimeSlot) => slot.room.title)
  }

  get hasNotes (): boolean {
    return !!(this.reservation && this.reservation.notes !== '')
  }

  async close () {
    if (this.reservation && this.reservation.adminNotes) {
      await sdk.setAdminNote({ id: this.reservation.id, note: this.reservation.adminNotes })
    }
    this.$emit('reload')
    this.$bvModal.hide('viewReservation')
  }

  async cancelManual () {
    if (!this.reservation) return
    if (!this.isManual) return

    await sdk.manualReservationCancel({ id: this.reservation.id })
    this.$emit('reload')
    this.$bvModal.hide('viewReservation')
  }

  async rebook () {
    if (!this.reservation) return
    this.$router.push({ name: 'rebook_reservation', params: { id: `${this.reservation.id}` } })
  }

  get waiter () {
    if (!this.reservation || !this.reservation.waiter) return
    const currentId = this.reservation.waiter.id
    const waiter = this.waiters.find((waiter) => waiter.id === currentId)
    if (!waiter) return
    return {
      value: waiter.id,
      label: `${waiter.firstname} ${waiter.lastname}`
    }
  }

  async adjustCost () {
    this.$v.refundState.$touch()
    if (this.$v.refundState.$anyError) return
    this.refunding = true
    await sdk.reservationRefund({ id: this.refundState.lineItemId, amount: this.refundAmount * 100 })
    this.$emit('reload')
    this.refunding = false
  }

  async setWaiter (event) {
    if (!this.reservation) return
    if (!event) {
      await sdk.setWaiter({ reservation: this.reservation.id })
      this.reservation.waiter = null
    } else {
      await sdk.setWaiter({ reservation: this.reservation.id, id: event.value })
    }
    this.$emit('reload')
  }

  get isUser () {
    return this.$currentUser.role === Role.User
  }

  async showCancelModal () {
    if (!this.reservation) return
    this.refundState.amount = this.reservation.refundableAmount / 100
    this.$bvModal.show('refundReservation')
  }

  time (date: Date) {
    return format(date, 'hh:mm a')
  }
}
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.paragraph {
  margin-bottom: 10px;
}
</style>
